

import axios from 'axios' //引入axios
import { Message, Loading } from 'element-ui'  //局部引入element的组件
import store from '@/store' //引入暴露出来的仓库实例
import local from './local'
import session from './session'


// 设置请求超时
axios.defaults.timeout = 10000

// 设置baseURL
axios.defaults.baseURL = store.state.baseURL

// let fullLoad = null

// 请求拦截器
axios.interceptors.request.use(config => {
  // 设置加载中
  /* fullLoad = Loading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0,0,0,0.7)'
  }) */

  // 公共服务平台请求头不需要携带token
  // let token = local.get('t_k')
  // config.headers.Authorization = `Bearer ${token}`

  return config
}, err => {
  // fullLoad.close()
  Message.error('请求发送出错')
  Promise.reject(err)
})

// 响应拦截器
axios.interceptors.response.use(res => {
  // fullLoad.close()  //拿到响应之后就关掉loading
  let { code, message } = res.data
  if (code !== 200) {
    Message.warning(message)  //弹后台响应的错误提示
  }

  // 公共服务平台没有token，不需要清理
  /* if (code===401){
    // 401代表未授权
    local.clear()
    location.href = `/#/portal-login?redirect=${router.currentRoute.path}`
  } */

  console.log('响应拦截')
  return res
}, err => { //没有拿到响应就会走这里
  console.log(err)
  // fullLoad.close()
  Promise.reject(err)
  if (err.response && err.response.status === 404) {
    Message.error('接口未找到')
    return
  }
  Message.error('响应超时')
})


// 暴露请求函数
export default {
  get: (url, params = {}) => new Promise((resolve, reject) => {
    axios.get(url, { params })
      .then(res => resolve(res.data))
      .catch(err => reject(err))
  }),
  post: (url, params = {}) => new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(res => resolve(res.data))
      .catch(err => reject(err))
  }),
  put: (url, params = {}) => new Promise((resolve, reject) => {
    axios.put(url, params)
      .then(res => resolve(res.data))
      .catch(err => reject(err))
  }),
  patch: (url, params = {}) => new Promise((resolve, reject) => {
    axios.patch(url, params)
      .then(res => resolve(res.data))
      .catch(err => reject(err))
  }),
  delete: (url, params = 0) => new Promise((resolve, reject) => {
    // /api/tb-content-info/{ id }
    axios.delete(url + params)
      .then(res => resolve(res.data))
      .catch(err => reject(err))
  }),


}




